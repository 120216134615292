import React from "react";
import "./styles/buttons.css";
const FilterButtons = ({ onButtonClick, selectedValue }) => {
  return (
    <div className="filter-button-container ">
      <div className="switch-field">
        <input
          type="radio"
          id="radio-one"
          name="switch-one"
          value="VACANT"
          checked={selectedValue === "VACANT"}
          onClick={onButtonClick}
        />
        <label htmlFor="radio-one">Available</label>
        <input
          type="radio"
          id="radio-two"
          name="switch-one"
          value="PENDING"
          checked={selectedValue === "PENDING"}
          onClick={onButtonClick}
        />
        <label htmlFor="radio-two">Selected</label>
        <input
          type="radio"
          id="radio-three"
          name="switch-one"
          value="RESERVED"
          checked={selectedValue === "RESERVED"}
          onClick={onButtonClick}
        />
        <label htmlFor="radio-three">Reserved</label>
      </div>
    </div>
  );
};

export default FilterButtons;
