import React from "react";
import "./styles/legends.css";
const Legend = () => {
  const legends = [
    {
      color: "green",
      label: "Available",
    },
    {
      color: "#green",
      border: "green",
      label: "Selected",
    },
    {
      color: "#d4d4d4",
      label: "Reserved",
    },
  ];
  return (
    <div className="legend-card-grid">
      <div className="legend-card-wrap">
        <div className="legend-legend-card">
          <div>
            <h4>Legends</h4>
            <div className="container">
              {legends.map((legend, index) => (
                <div key={index}>
                  <span
                    className={`circle-btn ${
                      legend.border ? "selected-circle" : ""
                    } `}
                    style={{
                      backgroundColor: legend.color,
                      borderStyle: "solid",
                      borderColor: legend.border
                        ? legend.border
                        : "transparent",
                    }}
                  ></span>
                  <span className="label">{legend.label}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Legend;
