import { Form, Input, Card, Button } from "antd";
import React from "react";
import "./styles/FormComponent.css";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 18,
  },
};

const CheckoutComponent = ({
  firstName,
  lastName,
  email,
  address,
  state,
  province,
  city,
  postalcode,
  contact,
  onChange,
}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate(-1); // This will navigate back to the previous page
  };

  const HoverIconButton = () => {
    return (
      <Button
        className="hover-icon-button"
        shape="circle"
        onClick={handleButtonClick}
      >
        <ArrowLeftOutlined className="icon" />
      </Button>
    );
  };

  return (
    <div className="form-container">
      <HoverIconButton />
      <Card title="Billing Information & Delivery Address" hoverable>
        <Form
          form={form}
          {...layout}
          style={{ padding: 40 }}
          className="form-card "
        >
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[{ required: true, message: "Please enter your full name" }]}
            value={firstName}
          >
            <Input onChange={onChange} name="firstName" />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[{ required: true, message: "Please enter your full name" }]}
            value={lastName}
          >
            <Input onChange={onChange} name="lastName" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please enter your email" },
              { type: "email", message: "Please enter a valid email" },
            ]}
            value={email}
          >
            <Input onChange={onChange} name="email" />
          </Form.Item>
          <Form.Item
            label="Contact"
            name="contact"
            rules={[
              { required: true, message: "Please enter your contact" },
              { type: "text", message: "Please enter a valid contact" },
            ]}
            value={email}
          >
            <Input onChange={onChange} name="contact" />
          </Form.Item>
          <Form.Item
            label="Address Line "
            name="address"
            rules={[
              { required: true, message: "Please enter your addressline 1" },
            ]}
            value={address}
          >
            <Input.TextArea name="address" onChange={onChange} />
          </Form.Item>

          <Form.Item
            label="City"
            name="city"
            rules={[{ required: true, message: "Please enter your city" }]}
            value={city}
          >
            <Input name="city" onChange={onChange} />
          </Form.Item>
          <Form.Item
            label="State"
            name="state"
            rules={[{ required: true, message: "Please enter your state" }]}
            value={state}
          >
            <Input name="state" onChange={onChange} />
          </Form.Item>
          <Form.Item
            label="Province"
            name="province"
            rules={[{ required: true, message: "Please enter your province" }]}
            value={province}
          >
            <Input name="province" onChange={onChange} />
          </Form.Item>
          <Form.Item
            label="Postal Code"
            name="postalCode"
            rules={[
              { required: true, message: "Please enter your postal code" },
            ]}
            postalcode={postalcode}
          >
            <Input onChange={onChange} name="postalCode" />
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default CheckoutComponent;
