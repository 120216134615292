import React, { useEffect, useState } from "react";
import { Circle, Group } from "react-konva";
import { SEAT_STATUS, SEAT_WIDTH, SPECIAL_IMAM_SEAT } from "../layout/layout";

const Seat = (props) => {
  const isReserved = props.data.status === SEAT_STATUS.RESERVED;
  const [isSeatVisible, setIsSeatVisible] = useState(true);

  useEffect(() => {
    setIsSeatVisible(
      !(
        (props.filter === SEAT_STATUS.RESERVED && !isReserved) ||
        (props.filter === SEAT_STATUS.VACANT && isReserved) ||
        (props.filter === SEAT_STATUS.PENDING &&
          (isReserved || !props.isSelected))
      )
    );
  }, [isReserved, props.isSelected, props.filter]);

  const onMouseEnter = (e) => {
    props.onHover(props.data, e.target.getAbsolutePosition());
    const container = e.target.getStage().container();
    if (isReserved) {
      container.style.cursor = "not-allowed";
    } else {
      container.style.cursor = "pointer";
    }
  };

  const onMouseLeave = (e) => {
    props.onHover(null);
    const container = e.target.getStage().container();
    container.style.cursor = "";
  };

  const onClick = (e) => {
    if (isReserved) {
      return;
    }
    if (props.isSelected) {
      props.onDeselect(props.data);
    } else {
      props.onSelect(props.data);
    }
  };

  const updatedSeatWidth =
    props.data.label === SPECIAL_IMAM_SEAT ? SEAT_WIDTH + 10 : SEAT_WIDTH;

  return (
    <Group
      x={props.x}
      y={props.y}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      <Circle
        x={0}
        y={0}
        radius={updatedSeatWidth / 2}
        fill={isReserved ? "#d4d4d4" : "green"}
        strokeWidth={1}
        visible={isSeatVisible}
        opacity={1}
        preventDefault={false}
      />
      {/* <Image
        width={SEAT_WIDTH}
        height={SEAT_HEIGHT}
        x={0}
        y={0}
        opacity={isReserved ? 0.3 : 1}
        image={props.image}
      /> */}
      {!isReserved && props.isSelected && (
        <Circle
          x={0}
          y={0}
          radius={updatedSeatWidth / 1.25}
          stroke={isSeatVisible ? "green" : ""}
          strokeWidth={3}
        />
      )}
    </Group>
  );
};

export default Seat;
