import { useState } from "react";
import axios from "axios";

const useCheckout = () => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const postData = async (url, data) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setResponse(response.data);
      setError(null);
    } catch (error) {
      setError(error);
      setResponse(null);
    }
  };

  return { response, error, postData };
};

export default useCheckout;
