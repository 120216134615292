import React from "react";
import { Group } from "react-konva";
import {
  IMAM_SEAT,
  SEATS_DISTANCE,
  SUBSECTION_PADDING,
} from "../layout/layout";
import Seat from "./Seat";

const SubSection = ({
  width,
  x,
  y,
  data,
  onHoverSeat,
  onSelectSeat,
  onDeselectSeat,
  selectedSeatId,
  image,
  filterStatus,
}) => {
  return (
    <Group x={x} y={y + SUBSECTION_PADDING + 10}>
      <Group x={0} y={0}>
        {Object.keys(data.seats_by_rows).map((rowKey, rowIndex) => {
          const row = data.seats_by_rows[rowKey];

          const _xPos = (width - row.length * SEATS_DISTANCE) / 2;

          return (
            <React.Fragment key={rowKey}>
              {row.map((seat, seatIndex) => {
                const xPosition =
                  seat.label === IMAM_SEAT
                    ? (width - 90) / 2
                    : seatIndex * SEATS_DISTANCE;

                return (
                  <Seat
                    key={seatIndex}
                    x={_xPos + xPosition}
                    y={rowIndex * SEATS_DISTANCE}
                    data={seat}
                    image={image}
                    sectionWidth={width}
                    filter={filterStatus}
                    onHover={onHoverSeat}
                    onSelect={onSelectSeat}
                    onDeselect={onDeselectSeat}
                    isSelected={selectedSeatId?.some(
                      (e) => e.label === seat.label
                    )}
                  />
                );
              })}
              {/* {row[0].label !== IMAM_SEAT && (
                <Text
                  text={rowKey}
                  x={-SEATS_DISTANCE / 1.5}
                  y={rowIndex * SEATS_DISTANCE}
                  fontSize={SEAT_WIDTH}
                  key={"label-left" + rowKey}
                />
              )} */}
            </React.Fragment>
          );
        })}
        {/* {data.seats_by_rows["1"]?.map((_, seatIndex) => {
          return (
            data.seats_by_rows["1"][0].label !== IMAM_SEAT && (
              <Text
                text={String.fromCharCode(seatIndex + 65)}
                x={seatIndex * SEATS_DISTANCE - 40}
                width={100}
                fontSize={SEAT_WIDTH}
                y={Object.keys(data.seats_by_rows).length * SEATS_DISTANCE}
                key={"label-bottom" + seatIndex}
                align="center"
              />
            )
          );
        })} */}
      </Group>
      {/* <Text
        text={
          data.seats_by_rows["1"][0].label !== IMAM_SEAT
            ? "TIER " + data.name
            : "1 SPACE"
        }
        fontStyle="bold"
        width={SUBSECTION_WIDTH}
        align="center"
        x={0}
        y={0}
      /> */}
    </Group>
  );
};

export default SubSection;
