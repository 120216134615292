import { Tag, Tooltip } from "antd";
import React from "react";
import { SEAT_STATUS } from "../layout/layout";
import "./styles/SeatPopup.css";

const isClickedInside = (e, element) => {
  let node = e.target;
  while (node) {
    if (node === element) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
};

const Popup = ({ position, seat, onClose, isSelected }) => {
  const containerRef = React.useRef(null);

  React.useEffect(() => {
    const onClick = (e) => {
      if (!isClickedInside(e, containerRef.current)) {
        onClose();
      }
    };
    window.addEventListener("click", onClick);
    return () => {
      window.removeEventListener("click", onClick);
    };
  }, [onClose]);
  const tooltipText = ` `;
  return (
    <div
      ref={containerRef}
      className="tooltip"
      style={{
        position: "absolute",
        top: position.y + "px",
        left: position.x - 52 + "px",
        padding: "10px",
        backgroundColor: "#D3D3D3",
        borderColor: "#D3D3D3",
        borderRadius: "3px",
        boxShadow: "10px 13px 11px -3px rgba(0,0,0,0.75)",
        zIndex: 10,
      }}
    >
      <div className="tooltiptext">
        <div>
          <span>Seat: {seat.label} &nbsp; </span>
          <div
            style={{
              fontWeight: "Bold",
              display: "inline",
            }}
          >
            ${seat.cost}
          </div>
        </div>
        <div style={{ marginTop: 10 }}>
          {isSelected ? (
            <Tag color="#b3b300">Selected</Tag>
          ) : seat.status === SEAT_STATUS.RESERVED ? (
            <Tag color="#5a5a5a">Reserved</Tag>
          ) : (
            <Tag color="#87d068">Available</Tag>
          )}
        </div>
      </div>
    </div>
  );
};

export default Popup;
