import React from "react";
import { Badge, Button } from "antd";
import { ShoppingCartOutlined } from "@ant-design/icons";

const BadgeButton = ({ cartItems, handleClick }) => {
  return (
    <Badge count={cartItems | 0}>
      <Button
        style={{ backgroundColor: "green" }}
        type="primary"
        shape="circle"
        icon={<ShoppingCartOutlined />}
        onClick={() => handleClick()}
      />
    </Badge>
  );
};

export default BadgeButton;
