import React from "react";
import { Group, Rect, Text } from "react-konva";
import {
  SECTION_TOP_PADDING,
  SPACE_TYPE,
  SUBSECTION_WIDTH,
} from "../layout/layout";
import SubSection from "./SubSection";

const Section = ({
  section,
  height,
  width,
  x,
  y,
  onHoverSeat,
  onSelectSeat,
  onDeselectSeat,
  selectedSeatId,
  image,
  filter,
}) => {
  const containerRef = React.useRef();
  React.useEffect(() => {}, [section, selectedSeatId]);

  let lastSubsectionY = 15;

  const getSpaceTypeLabel = () => {
    for (const spaceType of Object.keys(SPACE_TYPE)) {
      if (section.name === SPACE_TYPE[spaceType]) {
        return spaceType + "'S PRAYER SPACE";
      }
    }
  };

  return (
    <Group y={y} x={x} ref={containerRef}>
      <Rect
        width={width - SUBSECTION_WIDTH - 100}
        height={height}
        fill="white"
        cornerRadius={5}
        preventDefault={false}
      />
      <Text
        text={getSpaceTypeLabel()}
        height={SECTION_TOP_PADDING}
        width={width - SUBSECTION_WIDTH - 100}
        align="center"
        fontStyle="bold"
        verticalAlign="middle"
        fontSize={20}
      />

      {/* {section.map((subsection) => {
        const subHeight = getSubsectionHeight(subsection);
        const pos = lastSubsectionY + 20;
        lastSubsectionY += subHeight;

        return (
          
        );
      })} */}
      <SubSection
        x={0}
        y={lastSubsectionY}
        key={section.name}
        data={section}
        width={width - SUBSECTION_WIDTH - 100}
        image={image}
        height={height}
        filterStatus={filter}
        onHoverSeat={onHoverSeat}
        onSelectSeat={onSelectSeat}
        onDeselectSeat={onDeselectSeat}
        selectedSeatId={selectedSeatId}
      />
    </Group>
  );
};

export default React.memo(Section);
