import React, { useState } from "react";
import { Card, Checkbox, Table, Divider } from "antd";
import "./styles/orderSummary.css";
import Checkout from "./Checkout";
import signinbg from "../components/image/img.png";

const OrderSummary = ({
  product,
  totalCost,
  isAllFieldsFilled,
  deliveryInfo,
}) => {
  // const [checked, setChecked] = useState(false);

  // const columns = [
  //   {
  //     title: "Space",
  //     dataIndex: "label",
  //     key: "label",
  //   },

  //   {
  //     title: "Row",
  //     dataIndex: "row",
  //     key: "row",
  //   },
  //   {
  //     title: "Column",
  //     dataIndex: "column",
  //     key: "column",
  //   },
  //   {
  //     title: "Cost",
  //     dataIndex: "cost",
  //     key: "cost",
  //     render: (cost) => `$${cost}`,
  //     align: "right",
  //   },
  // ];
  const checkPlaceOrder = () => {
    return isAllFieldsFilled;
  };

  return (
    <div className="parent-order">
      <Card hoverable className="order-card" title="Selected Prayer Space">
        <br />

        {/* <div className="table-container">
          <Table
            // showHeader={false} // Hide the table header
            dataSource={product}
            columns={columns}
            pagination={false}
            scroll={{ y: 400 }} // Set the vertical scroll height to 400px
          />
        </div> */}

        <div style={{ paddingLeft: 24, paddingRight: 25 }}>
          {product.map((val) => {
            return (
              <span
                key={val.id}
                style={{
                  display: "block",
                  fontSize: 13,
                  paddingBottom: 10,
                }}
              >
                 {val.label} ({val.row}, {val.column})
                <span className="cost">${val.cost}</span>
              </span>
            );
          })}
          <Divider />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: 24,
            fontSize: 18,
            fontWeight: "bold",
            paddingRight: 24,
          }}
        >
          <h4> Total:</h4>
          <h4>${totalCost}</h4>
        </div>

        <Checkout
          checkPlaceOrder={checkPlaceOrder}
          totalCost={totalCost}
          deliveryInfo={deliveryInfo}
          label={product.map((val) => val.label)}
        />
      </Card>

      <br />
      <Card
        hoverable
        className="order-card"
        style={{ padding: 20 }}
        cover={
          <img src={signinbg} alt="" style={{ width: "100%", height: 250 }} />
        }
      ></Card>
    </div>
  );
};

export default OrderSummary;
