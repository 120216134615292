import React, { useCallback, useState, useEffect } from "react";
import SeatPopup from "./SeatPopup";
import Section from "./Section";
import { Drawer, notification } from "antd";
import axios from "axios";
import { Layer, Stage } from "react-konva";
import * as layout from "../layout/layout";
import Cart from "./Cart";
import "./styles/mainStageStyles.css";
import Legend from "./Legend";
import { CloseCircleFilled } from "@ant-design/icons";
import FilterButtons from "./FilterButtons";
import BadgeButton from "./BadgeButton";

const URL = "https://api.iioc.com/api";

const submitButtonId = "neon-pop-button6779c953ed584294a12c50b836dbbb65";

let resizeTimeout;

const MainStage = () => {
  const [jsonData, setJsonData] = useState([]);
  const [apiError, setApiError] = useState("");
  const containerRef = React.useRef(null);
  const stageRef = React.useRef(null);
  const [filterStatus, setFilterStatus] = React.useState("");
  const [image, setImage] = React.useState(null);
  const [sections, setSections] = React.useState(null);
  const [size, setSize] = React.useState({
    width: 1000,
    height: 1000,
  });
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const [selectedSeatId, setSelectedSeatId] = React.useState([]);

  const [popup, setPopup] = React.useState({ seat: null });

  React.useEffect(() => {
    function handleResize() {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        fetchData(false);
      }, 500);
    }
    window.addEventListener("resize", handleResize);
  });

  const onFilterButtonClick = (event) => {
    setFilterStatus(
      filterStatus === event.target.value ? "" : event.target.value
    );
  };

  const fetchData = useCallback(
    async (fetchData = true) => {
      try {
        let data = { data: jsonData };
        if (fetchData) {
          setApiError("");

          data = await axios.get(`${URL}/spot`);

          setJsonData(data.data);
        }
        const seatsSectionsObj = {
          [layout.SPACE_TYPE.IMAM]: {},
          [layout.SPACE_TYPE.MEN]: {},
          [layout.SPACE_TYPE.WOMEN]: {},
        };
        if (data.data) {
          for (const seat of data.data) {
            const seatLabelArr = seat.label.split("");

            if (!seatsSectionsObj[seatLabelArr[0]][seat.row]) {
              seatsSectionsObj[seatLabelArr[0]][seat.row] = [];
            }
            seatsSectionsObj[seatLabelArr[0]][seat.row].push({
              name: seat.column,
              ...seat,
            });
          }

          const _sections = Object.keys(seatsSectionsObj).map((spaceType) => {
            return {
              name: spaceType,
              seats_by_rows: seatsSectionsObj[spaceType],
            };
          });

          let maxHeight = layout.SECTION_TOP_PADDING;
          _sections.forEach((section) => {
            maxHeight +=
              layout.getSubsectionHeight(section) * 1 +
              layout.SECTIONS_MARGIN +
              layout.SECTIONS_MARGIN;
          });

          const newSize = {
            width:
              containerRef?.current?.offsetWidth < 1260
                ? 1260
                : containerRef?.current?.offsetWidth,
            height: maxHeight,
          };
          if (newSize.width !== size.width || newSize.height !== size.height) {
            setSize(newSize);
          }
          setSections(_sections);
        }
      } catch (error) {
        setApiError("Failed to load data");
      }
    },
    [setSize, size.height, size.width]
  );

  useEffect(() => {
    const storedSeats = localStorage.getItem("selectedSeats");

    if (document.getElementById(submitButtonId)) {
      document.getElementById(submitButtonId).disabled = true;
    }

    if (storedSeats) {
      const parsedSeats = JSON.parse(storedSeats);
      setSelectedSeatId(parsedSeats);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  React.useEffect(() => {
    const image = new window.Image();
    image.src = "/assets/prayer-tile.jpeg";
    const handleLoadedImage = () => {
      setImage(image);
    };
    image.addEventListener("load", handleLoadedImage);

    return () => {
      image.removeEventListener("load", handleLoadedImage);
    };
  }, []);

  let lastSectionPosition = 20;

  const handleHover = React.useCallback((seat, pos) => {
    setPopup({
      seat: seat,
      position: pos,
    });
  }, []);

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (message) => {
    api.info({
      message: "Error",
      description: message,
      placement: "topRight",
      duration: 2,
      icon: <CloseCircleFilled style={{ color: "#f01707" }} />,
    });
  };

  const handleSelect = React.useCallback(async (obj) => {
    try {
      const response = await axios.put(`${URL}/spot/reserve`, [
        {
          label: obj.label,
        },
      ]);
    } catch (error) {
      return openNotification("Failed to reserve the slot");
    }
    setSelectedSeatId((current) => [...current, obj]);

    if (window.openPopModal) {
      window.openPopModal();
    }

    // Retrieve the existing array from localStorage
    const storedSeats = localStorage.getItem("selectedSeats");
    const parsedSeats = storedSeats ? JSON.parse(storedSeats) : [];

    // Add the new object to the array
    const updatedSeats = [...parsedSeats, obj];

    // Store the updated array in localStorage
    localStorage.setItem("selectedSeats", JSON.stringify(updatedSeats));
  }, []);

  const handleDeselect = React.useCallback(async ({ label }) => {
    try {
      const response = await axios.put(`${URL}/spot/vacate`, [
        {
          label: label,
        },
      ]);
    } catch (error) {
      return openNotification("Failed to vacate the slot");
    }
    setSelectedSeatId((prevSelectedSeatId) =>
      prevSelectedSeatId.filter((seat) => seat.label !== label)
    );

    // Retrieve the existing array from localStorage
    const storedSeats = localStorage.getItem("selectedSeats");
    const parsedSeats = storedSeats ? JSON.parse(storedSeats) : [];

    // Remove the deselected seat from the array
    const updatedSeats = parsedSeats.filter((seat) => seat.label !== label);

    // Store the updated array in localStorage
    localStorage.setItem("selectedSeats", JSON.stringify(updatedSeats));
  }, []);

  if (jsonData === null) {
    return <div ref={containerRef}>Loading...</div>;
  }

  if (apiError) return <h2 style={{ textAlign: "center" }}>{apiError}</h2>;

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "lightgrey",
        flexWrap: "wrap",
      }}
    >
      {contextHolder}
      <div
        style={{
          position: "relative",
          backgroundColor: "lightgrey",
          width: "100%",
          height: "100%",
        }}
        ref={containerRef}
      >
        <div className="nav-header">
          <div className="wrapper">
            <Legend />
            <FilterButtons
              selectedValue={filterStatus}
              onButtonClick={onFilterButtonClick}
            />
          </div>
          <div style={{ paddingBottom: "20px" }}>
            <BadgeButton
              handleClick={showDrawer}
              cartItems={selectedSeatId.length}
            />
          </div>
          <Drawer
            title="Selected Prayer Space"
            placement="right"
            headerStyle={{ textAlign: "center" }}
            width={500}
            onClose={onClose}
            open={open}
          >
            <Cart seats={selectedSeatId} handleDeselect={handleDeselect} />
          </Drawer>
        </div>

        <Stage
          ref={stageRef}
          width={size.width}
          height={size.height}
          scale={{ x: 1, y: 1 }}
          style={{ overflowX: "scroll", overflowY: "hidden" }}
        >
          <Layer>
            {sections?.map((section, index) => {
              const height = layout.getSubsectionHeight(section);
              const position = lastSectionPosition;
              lastSectionPosition = position + height * 1;

              return (
                <Section
                  x={size.width - (size.width - layout.SUBSECTION_WIDTH + 50)}
                  y={position}
                  height={height * 1 + layout.SECTIONS_MARGIN}
                  width={size.width}
                  key={index}
                  section={section}
                  image={image}
                  filter={filterStatus}
                  selectedSeatId={selectedSeatId}
                  onHoverSeat={handleHover}
                  onSelectSeat={handleSelect}
                  onDeselectSeat={handleDeselect}
                />
              );
            })}
          </Layer>
        </Stage>
        {popup.seat && (
          <SeatPopup
            position={{
              x:
                stageRef.current?.getPointerPosition().x -
                stageRef.current?.getContainer().scrollLeft,
              y: stageRef.current?.getPointerPosition().y,
            }}
            seat={popup.seat}
            onClose={() => {
              setPopup({ seat: null });
            }}
            isSelected={selectedSeatId?.some(
              (e) => e.label === popup.seat.label
            )}
          />
        )}
      </div>
    </div>
  );
};

export default MainStage;
