export const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  contact: "",
  address: "",
  city: "",
  state: "",
  province: "",
  postalCode: "",
};

export function reducer(state, action) {
  const { property, value } = action.action;
  switch (action.type) {
    case "SET_VALUES":
      return { ...state, [property]: value };
    default:
      return state;
  }
}
